import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/about": [4],
		"/faq": [5],
		"/faq/services": [6],
		"/how": [7],
		"/how/digital-services": [8],
		"/how/digital-services/link-multiple-domains/dns": [~9],
		"/how/digital-services/link-multiple-domains/via": [~10],
		"/syntax": [11],
		"/tools": [12,[2]],
		"/tools/builder": [13,[2]],
		"/tools/checker": [14,[2]],
		"/tools/validator": [15,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';